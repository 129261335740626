import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '@/config/routes/api'

const qs = require('qs')

export default {
	async getAll(datafield) {
		const params = qs.stringify({
			order: 'desc',
			order_by: 'short_name',
			offset: 0,
			limit: 0,
			filters: {
				'0': {
					field: 'list_name',
					operator: 'CONTAINS',
					value: datafield,
				},
			},
		})

		const { data } = await useJwt.get(`${API_ROUTES.datafields.get}?${params}`)

		return data
	},
}
